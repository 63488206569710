/* eslint-disable prettier/prettier */

;
(function ($) {
  $(function () {
    'use strict'

    // slick slider product gallery
    $('.flex-control-nav').slick({
      slidesToShow: 3,
      infinite: false,
      adaptiveHeight: true
    })

    // relate product
    // $('.related .products').slick({
    //   slidesToShow: 4,
    //   infinite: true,
    //   dots: true
    // })

    // last view product
    $('.last_product_view .products , .related .products').slick({
      slidesToShow: 4,
      infinite: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }]
    })

    // product category list slide multiple row
    if ($('.slick_on').length) {
      $('.slick_on').slick({
        rows: 2,
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
          breakpoint: 991,
          settings: {
            rows: 4,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        }]
      })
    }

    // slick-slider-product-hightlight
    if ($('.slick-slider-product-hightlight').length) {
      $('.slick-slider-product-hightlight').slick({
        slidesToShow: 4,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        responsive: [{
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        }]
      })
    }

    // product category filter
    $(document).ajaxComplete(function () {
      filterProduct()
    })
    $(window).on('load', function () {
      filterProduct()
    })

    function filterProduct() {
      $('.select-checkbox').remove()
      $('.select-filter [type="checkbox"]').each(function () {
        $(this).after('<div class="select-checkbox"></div>')
      })

      $('.select-checkbox').click(function (e) {
        e.stopPropagation()
        e.preventDefault()
        $(this)
          .parent()
          .find('label')
          .click()
      })

      $('.br_child_toggle').click(function () {
        let parentLi = $(this)
          .parent()
          .parent()
        if (parentLi.find('.fa-minus').length) {
          parentLi.removeClass('li_active')
        } else {
          parentLi.addClass('li_active')
        }
      })
    }

    // sidebar active current category
    $(document).ready(function () {
      $('.product_categories_menu .cate-item.has-child.active')
        .find('.sub-category')
        .show()
    })

    // sidebar cate menu list
    $('.product_categories_menu .cate-item.has-child').click(function (e) {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        $(this)
          .find('.sub-category')
          .slideUp()
      } else {
        $(this).addClass('active')
        $(this)
          .find('.sub-category')
          .slideDown()
      }
    })
    // show collape current category
    $('.current_cate')
      .parent()
      .parent()
      .addClass('active')

    // mobile select cate filter
    $('.select_category_mobile').change(function () {
      window.location.href = $(this).val()
    })

    // mobile filter btn
    $('.sidebar-filter-btn').click(function () {
      $('.sidebar-filter').slideDown({
        complete: function () {
          $('.filter-btn-section')
            .fadeIn('slow')
            .addClass('fixed')
        }
      })
    })
    $('.filter-submit , .reset-filter-btn').click(function () {
      $('.filter-btn-section').fadeOut('fast', function () {
        $('.sidebar-filter').slideUp({
          complete: function () {
            $('.filter-btn-section').removeClass('fixed')
          }
        })
      })
      $(window).scrollTop(0)
    })

    // select2
    $(document).ready(function () {
      $('.gfield_select').select2({
        minimumResultsForSearch: -1
      })
    })

    // get product name to hubspot form
    $(window).on('load', function () {
      $('[name="product_name_"]').val(
        $('.enq-product-name')
        .text()
        .trim()
      )
    })
  }) // End Jquery
})(window.jQuery)